import { LoadServiceError, SuspenseLoader } from 'ap-core'
import {
  $KYCLevel,
  $KYCLink,
  $accountsLocations,
  $assetsInterfaces,
  $email,
  $invited,
  $referralToken,
  $tgUsername,
  $theme,
  $translate,
  $userDetails,
  $username,
  $verificationType,
  assetsInterfacesChanged,
  createApiRetryEffect,
  getKYCLevelFx,
  getKYCOperationLimitFx,
  getUserDetailsFx,
  getUserEndpoints,
  notifyFx,
  pinModalIDChanged,
  switchLangChanged,
  themeChanged,
} from 'entities'
import { importRemote } from 'module-federation-import-remote'
import { prop } from 'ramda'
import { FC, lazy } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import {
  StyledScrollArea,
  getGlobalArg,
  triggerKYCModal,
  triggerSentryError,
  useGetLayoutSpacings,
} from 'shared'
import { SettingsSrvProps } from 'srv-declarations'

const userEndpoints = getUserEndpoints()

const SettingsService = lazy<FC<SettingsSrvProps>>(() =>
  importRemote({
    url: getGlobalArg('SETTINGS_REMOTE_LINK'),
    scope: 'remoteSettings',
    module: 'Settings',
  })
)

export const SettingsPage = () => {
  const spacing = useGetLayoutSpacings()
  const { t } = useTranslation()

  return (
    <StyledScrollArea
      h={`calc(100dvh - ${spacing.vertical.top} - ${spacing.vertical.bottom})`}
    >
      <ErrorBoundary onError={triggerSentryError} fallback={<LoadServiceError t={t} />}>
        <SuspenseLoader>
          <SettingsService
            createFxFabric={createApiRetryEffect}
            reqData={{
              userEndpoints: {
                PASS_TG_CODE: prop('PASS_TG_CODE', userEndpoints),
              },
            }}
            stores={{
              $theme,
              $referralToken,
              $invited,
              $translate,
              $KYCLevel,
              $KYCLink,
              $accountsLocations,
              $userDetails,
              $username,
              $tgUsername,
              $verificationType,
              $email,
              $assetInterfaces: $assetsInterfaces,
            }}
            fxs={{
              notifyFx,
              getUserDetailsFx,
              getKYCOperationLimitFx,
              getKYCLevelFx,
            }}
            events={{
              assetInterfacesChanged: assetsInterfacesChanged,
              themeChanged,
              triggerSentryError,
              switchLangChanged,
              pinModalIDChanged,
              triggerKYCModal,
            }}
          />
        </SuspenseLoader>
      </ErrorBoundary>
    </StyledScrollArea>
  )
}
