import { propOr } from 'ramda'

const globalEnvs = {
  WS_URL: propOr('', 'REACT_APP_WS_URL', window) as string,
  BASE_API_URL: propOr('', 'REACT_APP_BASE_API_URL', window) as string,
  FILE_API_URL: propOr('', 'FILE_API_URL', window) as string,
  LANDING_URL: propOr('', 'REACT_APP_LANDING_URL', window) as string,
  CURRENT_ENV: propOr('', 'REACT_APP_CURRENT_ENV', window) as string,
  DOMAIN: propOr('', 'REACT_APP_DOMAIN', window) as string,
  LOGIN_REMOTE_LINK: propOr('', 'LOGIN_REMOTE_LINK', window) as string,
  REGISTRATION_REMOTE_LINK: propOr('', 'REGISTRATION_REMOTE_LINK', window) as string,
  HISTORY_REMOTE_LINK: propOr('', 'HISTORY_REMOTE_LINK', window) as string,
  ASSETS_LIST_REMOTE_LINK: propOr('', 'ASSETS_LIST_REMOTE_LINK', window) as string,
  ASSET_OPERATIONS_REMOTE_LINK: propOr(
    '',
    'ASSET_OPERATIONS_REMOTE_LINK',
    window
  ) as string,
  SETTINGS_REMOTE_LINK: propOr('', 'SETTINGS_REMOTE_LINK', window) as string,
  PAYMENTS_REMOTE_LINK: propOr('', 'PAYMENTS_REMOTE_LINK', window) as string,
  WS_CHAT_URL: propOr('', 'WS_CHAT_URL', window) as string,
  CHAT_CORE_REMOTE_LINK: propOr('', 'CHAT_CORE_REMOTE_LINK', window),
}
export const getGlobalArg = (argName: keyof AppConfig) =>
  propOr<string, typeof globalEnvs, string>('', argName, globalEnvs)
