import { Flex, MediaQuery, Paper } from '@mantine/core'
import { LoadServiceError, ProcessingResponse, SuspenseLoader } from 'ap-core'
import { $navigate, $translate, notifyFx, withErrorNotification } from 'entities'
import { importRemote } from 'module-federation-import-remote'
import { FC, lazy } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import {
  StyledScrollArea,
  getGlobalArg,
  getLayoutHeight,
  processingResponseKy,
  request,
  triggerSentryError,
  useGetLayoutSpacings,
} from 'shared'
import { RegistrationSrvProps } from 'srv-declarations'

const AuthBanner = lazy(() => import('entities/ui/auth-banner'))

const RegistrationService = lazy<FC<RegistrationSrvProps>>(() =>
  importRemote({
    url: getGlobalArg('REGISTRATION_REMOTE_LINK'),
    scope: 'remoteRegistration',
    module: 'Registration',
  })
)

export const RegistrationPage = () => {
  const spacing = useGetLayoutSpacings()
  const { t } = useTranslation()

  return (
    <StyledScrollArea
      h={{
        base: `calc(100dvh - ${getLayoutHeight(spacing)})`,
      }}
    >
      <Flex h='100%' justify='center' gap='0.5rem'>
        <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
          <Paper
            w={{ base: '100%', md: '50%' }}
            shadow='md'
            withBorder
            radius='lg'
            component={Flex}
          >
            <Flex
              mah={500}
              maw={500}
              justify='center'
              align='center'
              m='auto'
              pos='relative'
            >
              <SuspenseLoader>
                <AuthBanner />
              </SuspenseLoader>
            </Flex>
          </Paper>
        </MediaQuery>
        <Paper
          w={{ base: '100%', md: '50%' }}
          shadow='md'
          withBorder
          radius='lg'
          component={Flex}
        >
          <Flex
            w='100%'
            justify='center'
            align='center'
            direction='column'
            maw='32rem'
            gap='lg'
            m='auto'
            p='sm'
            pos='relative'
          >
            <ErrorBoundary
              onError={triggerSentryError}
              fallback={<LoadServiceError t={t} />}
            >
              <SuspenseLoader>
                <RegistrationService
                  stores={{
                    $translate,
                    $navigate,
                  }}
                  fxs={{
                    notify: notifyFx,
                  }}
                  reqData={{
                    landingUrl: getGlobalArg('LANDING_URL'),
                    kyInstance: request,
                    processingResponse: processingResponseKy as ProcessingResponse,
                  }}
                  createFxFabric={withErrorNotification}
                />
              </SuspenseLoader>
            </ErrorBoundary>
          </Flex>
        </Paper>
      </Flex>
    </StyledScrollArea>
  )
}
