import styled from '@emotion/styled'
import { ScrollArea } from '@mantine/core'

export const StyledScrollArea = styled(ScrollArea)`
  border-radius: 12px;
  .mantine-ScrollArea-viewport > div {
    height: 100%;
  }
  .mantine-ScrollArea-scrollbar {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`
