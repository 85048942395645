import { KyResponse } from 'ky'
import { prop } from 'ramda'
const promise = <T>(body: T, isOk?: boolean) =>
  new Promise((resolve, reject) => {
    const handler = isOk ? resolve : reject
    handler(body)
  }) as Promise<any>

export const processingResponseKy = async <T>(
  fn: () => Promise<KyResponse>,
  skipParsResToJson = false
): Promise<T> => {
  const res = await fn()
  const ok = prop('ok', res)
  try {
    const resBody = skipParsResToJson ? res : ((await res.json()) as any)

    return promise(resBody, ok)
  } catch (e) {
    return promise('', ok)
  }
}
