import { T, always, cond, includes, prop, values } from 'ramda'
import { safeMap } from './safe'

export type RouteSignature = {
  path: string
  permissionsIdsToAccess: number[]
}

export type AuthUrl = 'login' | 'signUp' | 'signUpWithToken'

export type MainUrl = 'wallet' | 'payments' | 'history' | 'settings'

export type NotFoundPath = 'notFound'

type TURLs<T extends string> = {
  [key in T]: RouteSignature
}

export const URLs: TURLs<MainUrl> = {
  wallet: {
    path: '/wallet',
    permissionsIdsToAccess: [],
  },
  payments: {
    path: '/payments',
    permissionsIdsToAccess: [],
  },
  history: {
    path: '/history',
    permissionsIdsToAccess: [],
  },
  settings: {
    path: '/settings',
    permissionsIdsToAccess: [],
  },
} as const

export const AuthURLs: TURLs<AuthUrl> = {
  login: {
    path: '/login',
    permissionsIdsToAccess: [],
  },
  signUp: {
    path: '/registration',
    permissionsIdsToAccess: [],
  },
  signUpWithToken: {
    path: '/registration/*',
    permissionsIdsToAccess: [],
  },
} as const

const detectPathAndAuthRotes = () => {
  const detectPath = cond([
    [includes('login'), always('login')],
    [includes('registration'), always('registration')],
    [T, always('unknown')],
  ])
  const mainPath = cond([
    [includes('wallet'), always('wallet')],
    [includes('payments'), always('payments')],
    [includes('settings'), always('settings')],
    [includes('history'), always('history')],
  ])
  const privateRoutes = safeMap(prop('path'), values(URLs))
  const authRotes = safeMap(prop('path'), values(AuthURLs))

  return { detectPath, mainPath, authRotes, privateRoutes }
}

export const isAuthRoutes = (splitPath: string[]) => {
  return includes(
    `/${detectPathAndAuthRotes().detectPath(splitPath)}`,
    detectPathAndAuthRotes().authRotes
  )
}

export const isMainRoutes = (splitPath: string[]) => {
  return includes(
    `/${detectPathAndAuthRotes().mainPath(splitPath)}`,
    detectPathAndAuthRotes().privateRoutes
  )
}

export const getAppPath = (keyWord: MainUrl | AuthUrl | NotFoundPath) => {
  const authPaths = {
    signUp: '/registration',
    signUpWithToken: '/registration/*',
    login: '/login',
    wallet: '/wallet',
    payments: '/payments',
    settings: '/settings',
    notFound: '/not-found',
  } as Record<MainUrl | AuthUrl | NotFoundPath, string>

  return prop(keyWord, authPaths)
}
