import { closeAllModals, openContextModal } from '@mantine/modals'
import { useTour } from '@reactour/tour'
import { Fn, LoadServiceError, SuspenseLoader, constructEndpoint } from 'ap-core'
import { useUnit } from 'effector-react'
import {
  $P2PPaymentStatus,
  $accountsLocations,
  $activeAccount,
  $activeMobileTab,
  $activeWallet,
  $assetsInterfaces,
  $cryptoList,
  $currencyList,
  $currentAssetHistoryId,
  $fiatList,
  $rate,
  $roundToByCurrency,
  $techSupportLink,
  $translate,
  $ungroupedAssets,
  $userDetails,
  $username,
  $verificationType,
  $walletsList,
  ThreeColumns,
  activeAccountChanged,
  activeWalletChanged,
  createApiRetryEffect,
  expandedWalletIdChanged,
  getAssetsFx,
  getKYCLevelFx,
  getKYCOperationLimitFx,
  getUserEndpoints,
  handleOpenTransactionModal,
  isNotDemo,
  mobileActiveTabChanged,
  notifyFx,
  resetOperationsStore,
  toConfirmWithdrawFx,
  triggerGetAssets,
  triggerGetKYCOperationLimit,
  triggerResetHistory,
  triggerUpdateAssetsHistory,
  ungroupedAssetsChanged,
  userRateChanged,
} from 'entities'
import { LimitInfo } from 'features/KYC-info'
import { importRemote } from 'module-federation-import-remote'
import certBgImg from 'public/certificate-bg.png'
import { prop, propOr } from 'ramda'
import { FC, lazy, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { getGlobalArg, triggerKYCModal, triggerSentryError } from 'shared'
import {
  AssetOperationsSrvProps,
  AssetsListSrvProps,
  HistorySrvProps,
} from 'srv-declarations'

const userEndpoints = getUserEndpoints()

const HistoryService = lazy<FC<HistorySrvProps>>(() =>
  importRemote({
    url: getGlobalArg('HISTORY_REMOTE_LINK'),
    scope: 'remoteHistory',
    module: 'History',
  })
)
const AssetsListService = lazy<FC<AssetsListSrvProps>>(() =>
  importRemote({
    url: getGlobalArg('ASSETS_LIST_REMOTE_LINK'),
    scope: 'remoteAssetsList',
    module: 'List',
  })
)
const AssetOperationsService = lazy<FC<AssetOperationsSrvProps>>(() =>
  importRemote({
    url: getGlobalArg('ASSET_OPERATIONS_REMOTE_LINK'),
    scope: 'remoteAssetOperations',
    module: 'Operations',
  })
)

export const WalletsPage = () => {
  const { setIsOpen } = useTour()
  const { t } = useTranslation()

  const { 0: active, 1: assetLayout } = useUnit([$activeMobileTab, $assetsInterfaces])

  useEffect(() => {
    if (!localStorage.getItem('intro-tour-completed')) {
      openContextModal({
        size: 'lg',
        modal: 'introTourModal',
        closeOnClickOutside: false,
        closeOnEscape: false,
        innerProps: {
          setIsOpen,
        },
      })
      return
    }
  }, [])

  const inter = {
    list: (
      <AssetsListService
        createFxFabric={createApiRetryEffect}
        stores={{
          $userName: $username,
          $translate,
          $activeAccount,
          $activeWallet,
          $cryptoList,
          $currencyList,
          $fiatList,
          $roundToByCurrency,
          $ungroupAssets: $ungroupedAssets,
        }}
        fxs={{
          notify: notifyFx,
          getAssetsFx,
        }}
        fns={{
          ungroupedAssetsChanged,
          activeAccountChanged,
          activeWalletChanged,
          mobileActiveTabChanged,
          closeAllModals: closeAllModals as Fn<void, void>,
          expandedWalletIdChanged,
        }}
      />
    ),
    operations: (
      <AssetOperationsService
        modules={{ LimitInfo, certBgImg }}
        events={{
          assetHistoryChanged: triggerUpdateAssetsHistory,
          ratesChanged: userRateChanged,
          triggerKYCModal,
          triggerGetAssets,
          triggerGetKYCOperationLimit,
          resetOperationsStore,
        }}
        fxs={{
          notify: notifyFx,
          getAssetsFx,
          getKYCLevelFx,
          getKYCOperationLimitFx,
          toConfirmWithdrawFx,
        }}
        stores={{
          $techSupportLink,
          $translate,
          $activeAccount,
          $activeWallet,
          $cryptoList,
          $currencyList,
          $fiatList,
          $roundToByCurrency,
          $accountsLocations,
          $userSettings: $userDetails,
          $rate,
          $verificationType,
          $walletsList,
          $P2PPaymentStatus,
        }}
        reqData={{
          userEndpoints: {
            PASS_TG_CODE: prop('PASS_TG_CODE', userEndpoints),
          },
        }}
        createFxFabric={createApiRetryEffect}
      />
    ),
    history: (
      <HistoryService
        createFxFabric={createApiRetryEffect}
        stores={{
          $roundToByCurrency,
          $currentHistoryId: $currentAssetHistoryId,
        }}
        fxs={{
          notify: notifyFx,
        }}
        subHash='assets'
        fns={{
          isNotDemo,
          handleOpenTransactionModal,
          triggerResetStores: triggerResetHistory,
          historyChangedByWS: triggerUpdateAssetsHistory,
        }}
        reqData={{
          historyEndpoint: constructEndpoint('/tx'),
        }}
      />
    ),
  }
  const left = prop(0, assetLayout)
  const middle = prop(1, assetLayout)
  const right = prop(2, assetLayout)

  return (
    <ThreeColumns
      hide={{
        left: !prop('visible', left),
        middle: !prop('visible', middle),
        right: !prop('visible', right),
      }}
      active={active}
      onChangeTab={mobileActiveTabChanged}
      left={
        <ErrorBoundary onError={triggerSentryError} fallback={<LoadServiceError t={t} />}>
          <SuspenseLoader>{propOr(null, prop('name', left), inter)}</SuspenseLoader>
        </ErrorBoundary>
      }
      middle={
        <ErrorBoundary onError={triggerSentryError} fallback={<LoadServiceError t={t} />}>
          <SuspenseLoader>{propOr(null, prop('name', middle), inter)}</SuspenseLoader>
        </ErrorBoundary>
      }
      right={
        <ErrorBoundary onError={triggerSentryError} fallback={<LoadServiceError t={t} />}>
          <SuspenseLoader>{propOr(null, prop('name', right), inter)}</SuspenseLoader>
        </ErrorBoundary>
      }
    />
  )
}
