import { useViewportSize } from '@mantine/hooks'
import { compareWithAndBreakPoint } from 'ap-core'
import {
  equals,
  gt,
  map,
  path,
  prop,
  propEq,
  replace,
  sum,
  toString,
  values,
} from 'ramda'
import { safePipe } from '../safe'

type HeaderHeight = {
  md: string
  sm: string
}

export const isDark = equals('dark')
const getNumWithoutRem = (rem: string): number => {
  return Number(rem.replace('rem', ''))
}

const getPaddingTop = (
  breackpoint: 'md' | 'sm',
  padding: string,
  headerHeight: HeaderHeight
) => {
  if (equals('md', breackpoint)) {
    return `${
      getNumWithoutRem(headerHeight.md) +
      getNumWithoutRem(padding) +
      getNumWithoutRem(padding)
    }rem`
  }
  if (equals('sm', breackpoint)) {
    return `${
      getNumWithoutRem(headerHeight.sm) +
      getNumWithoutRem(padding) +
      getNumWithoutRem(padding)
    }rem`
  }
}
export const useGetLayoutSpacings = () => {
  const { width } = useViewportSize()

  const headerHeight = {
    md: '4.5rem',
    sm: '2.5rem',
  }

  const vertical = {
    md: { top: getPaddingTop('md', '1.5rem', headerHeight) as string, bottom: '1.5rem' },
    sm: { top: getPaddingTop('sm', '1.5rem', headerHeight) as string, bottom: '1rem' },
  }

  const horizontal = {
    md: { right: '2.5rem', left: '2.5rem' },
    sm: { right: '1rem', left: '1rem' },
  }

  return {
    vertical: compareWithAndBreakPoint(gt, width, 'md') ? vertical.md : vertical.sm,
    horizontal: compareWithAndBreakPoint(gt, width, 'md') ? horizontal.md : horizontal.sm,
    measure: 'rem',
    headerHeight,
  }
}

export const useGetHeaderSpacings = () => {
  const { width } = useViewportSize()

  const vertical = {
    md: { top: '0.3rem', bottom: '0.3rem' },
    sm: {
      top: '0.55rem',
      bottom: '0.55rem',
    },
  }

  const horizontal = {
    md: { right: '1.5rem', left: '1.5rem' },
    sm: { right: '0.5rem', left: '0.5rem' },
  }

  return {
    vertical: compareWithAndBreakPoint(gt, width, 'md') ? vertical.md : vertical.sm,
    horizontal: compareWithAndBreakPoint(gt, width, 'md') ? horizontal.md : horizontal.sm,
    measure: 'rem',
  }
}

type spacingType = {
  vertical: { top: string; bottom: string }
  horizontal: { right: string; left: string }
  measure: string
  headerHeight: { md: string; sm: string }
}

export const getLayoutHeight = (spacing: spacingType) =>
  safePipe(
    prop('vertical'),
    values,
    map(replace(spacing.measure, '')),
    map(Number),
    sum,
    toString,
    (result: string) => result + spacing.measure
  )('0px')(spacing)

export const getLayoutPaddings = (spacing: {
  vertical: { top: string; bottom: string }
  horizontal: { right: string; left: string }
  measure: string
  headerHeight: { md: string; sm: string }
}) =>
  `${path(['vertical', 'top'], spacing)} ${path(['horizontal', 'right'], spacing)} ${path(
    ['vertical', 'bottom'],
    spacing
  )} ${path(['horizontal', 'left'], spacing)}`

export const isDarkTheme = propEq('dark', 'colorScheme')

export * from './sx-break-points'
