import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { getLocale } from 'ap-core'
import ky from 'ky'
import { clone, equals, includes, prop } from 'ramda'
import { getGlobalArg } from '../lib'
import {
  addToFailed401ReqChanged,
  openRefreshOverlay,
  triggerCaptchaChanged,
  triggerKYCModal,
  triggerLogout,
} from '../model'

const getRetryStatuses = () => [401, 429, 430]
const getRetryMessages = () => ['INVALID_ACCESS_TOKEN', '!captchaOK', 'out of limit']

const processError = async (req: Request, response: Response) => {
  const shouldRetry = Boolean(req.headers.get('shouldRetry'))

  const addToFailedReqs = async () => {
    if (shouldRetry) {
      try {
        const reqBody = await req.json()
        addToFailed401ReqChanged({ url: req.url, body: reqBody })
      } catch (e) {
        addToFailed401ReqChanged({ url: req.url })
      }
      return
    }
  }

  const clonedRes = clone(response)
  if (!isNilOrEmpty(prop('body', response))) {
    try {
      const json = await clonedRes.json()

      if (
        equals(response.status, 401) &&
        includes(prop('message', json), ['INVALID_REFRESH_TOKEN', 'Unauthorized'])
      ) {
        return triggerLogout()
      }
      if (
        equals(response.status, 401) &&
        includes(prop('message', json), getRetryMessages())
      ) {
        addToFailedReqs()
        return openRefreshOverlay()
      }
      if (equals(response.status, 429)) {
        addToFailedReqs()
        return triggerCaptchaChanged(json)
      }
      if (
        equals(response.status, 430) &&
        includes(prop('message', json), getRetryMessages())
      ) {
        return triggerKYCModal({ isError: true })
      }
    } catch (e) {
      // triggerSentryError(e)
      return null
    }
    return null
  }
  return null
}

export const createRequest = (prefixUrl?: string | URL | undefined) =>
  ky
    .create({
      credentials: 'include',
      prefixUrl,
      throwHttpErrors: false,
      timeout: 30000,
    })
    .extend({
      hooks: {
        beforeRequest: [({ headers }) => headers.append('locale', getLocale())],
        afterResponse: [
          async (request, _, response) => {
            if (includes(response.status, getRetryStatuses())) {
              await processError(request, response)
              return response
            }

            return response
          },
        ],
      },
    })

export const request = createRequest(getGlobalArg('BASE_API_URL'))
export const requestFile = createRequest(getGlobalArg('FILE_API_URL'))
