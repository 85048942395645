import { isNilOrEmpty } from '@aifory/lil-tools-228'
import { useNetwork } from '@mantine/hooks'
import { always, equals, F, ifElse, lt, prop, propOr } from 'ramda'

export const checkSlowConnection = (connection: ReturnType<typeof useNetwork>) =>
  ifElse(
    isNilOrEmpty,
    F,
    always(
      equals(prop('effectiveType', connection), 'slow-2g') ||
        lt(propOr(0, 'downlink', connection), 0.5)
    )
  )(prop('downlink', connection))
