import { TFnArg } from 'ap-core'
import { includes } from 'ramda'

export const getErrorMessage = (err: string) => {
  const errors = [
    'INVALID_REFRESH_TOKEN',
    'INVALID_AUTH_TOKEN',
    'TOO_MANY_ATTEMPTS_LOG_IN_AGAIN',
    'UNAUTHORIZED',
    'INVALID_ACCESS_TOKEN',
    'INVALID_SSE_TOKEN',
    'USER_NOT_FOUND',
    'INVALID_PASSWORD',
    'INVALID_CONFIRMATION_CODE',
    'INVALID_CREDENTIALS',
    'EXCHANGE_UNABLED',
    'INVALID_OTP_CODE',
    'INVALID_INVITE_TOKEN',
    'LOGIN_ALREADY_USED',
    'INVALID_PIN',
    'INVALID_REQUEST_ID',
    'INVALID_VERIFICATION_TOKEN',
    'TELEGRAM_ACCOUNT_ALREADY_USED',
    'BAD_REQUEST',
    'INVALID_RECOVERY_PHRASE',
    'NO_CONFIRMED_EMAIL',
    'INVALID_LOGIN_OR_CODE',
    'ALREADY_HAVE_EMAIL',
    'EMAIL_ALREADY_USED',
    'INVALID_EMAIL',
    'TOO_MANY_ATTEMPTS',
    'NEED_TO_LINK_EMAIL',
    'INVALID_LOGIN_OR_PASSWORD',
    'TG_IS_NOT_LINKED',
    'LOGIN_OR_MAIL_NOT_EXIST',
    'BOT_WAS_BLOCKED',
    'ACCOUNT_WAS_DELETED',
    'ACCOUNT_WAS_BANNED',
    'ORDER_HAS_NOT_ENGINE ORDER_ID',
    'NOT_ENOUGH_FUNDS',
  ]

  if (includes(err, errors)) {
    return (err as unknown) as TFnArg
  }

  return ('UNKNOWN_ERROR' as unknown) as TFnArg
}
