import { constructEndpoint, LoadServiceError, SuspenseLoader, Transaction } from 'ap-core'
import { useUnit } from 'effector-react'
import {
  $accountsLocations,
  $activeAccount,
  $activeMobileTab,
  $cryptoList,
  $currencyList,
  $fiatList,
  $KYCLevel,
  $rate,
  $roundToByCurrency,
  $selectedPaymentMethod,
  $selectedPaymentsHistory,
  $techSupportLink,
  $translate,
  $verificationType,
  $walletsList,
  createApiRetryEffect,
  getLegalOrderDetailsFx,
  getUserEndpoints,
  isNotDemo,
  legalOrderDetailsChanged,
  mobileActiveTabChanged,
  notifyFx,
  orderDetailsChanged,
  paymartOrderDetailsChanged,
  resetOperationsStore,
  ThreeColumns,
  toConfirmWithdrawFx,
  toggleChat,
  triggerPaymentMethodChanged,
  triggerResetHistory,
  triggerUpdateAssetsHistory,
} from 'entities'
import { LimitInfo } from 'features/KYC-info'
import { importRemote } from 'module-federation-import-remote'
import { equals, includes, mergeDeepRight, prop, propOr } from 'ramda'
import { FC, lazy } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { getGlobalArg, triggerKYCModal, triggerSentryError } from 'shared'
import { HistorySrvProps, PaymentsSrvProps } from 'srv-declarations'

const userEndpoints = getUserEndpoints()

const PaymentsList = lazy(() => import('features/payments-list'))

const HistoryService = lazy<FC<HistorySrvProps>>(() =>
  importRemote({
    url: getGlobalArg('HISTORY_REMOTE_LINK'),
    scope: 'remoteHistory',
    module: 'History',
  })
)
const PaymentsOperationsService = lazy<FC<PaymentsSrvProps>>(() =>
  importRemote({
    url: getGlobalArg('PAYMENTS_REMOTE_LINK'),
    scope: 'remotePayments',
    module: 'Operations',
  })
)

const handleOpenTransactionModal = (item: Transaction) => {
  if (item) {
    const type = prop('orderType', item) || prop('type', item)
    const orderID =
      (propOr('', 'orderID', item) as string) || propOr('', 'processID', item)
    const isLegalPayment = includes('cbTransfer', type || '')

    if (isLegalPayment) {
      return legalOrderDetailsChanged({ orderID })
    }

    if (equals('paymartWithdrawal', type)) {
      return paymartOrderDetailsChanged(
        mergeDeepRight({ type: type, processID: orderID }, item)
      )
    }

    orderDetailsChanged(
      mergeDeepRight(
        { type: prop('orderType', item), processID: prop('orderID', item) },
        item
      )
    )
  }
}

export const PaymentsPage = () => {
  const { t } = useTranslation()
  const { 0: active, 1: selectedPaymentsHistory } = useUnit([
    $activeMobileTab,
    $selectedPaymentsHistory,
  ])
  return (
    <ThreeColumns
      active={active}
      onChangeTab={mobileActiveTabChanged}
      left={
        <SuspenseLoader>
          <PaymentsList />
        </SuspenseLoader>
      }
      middle={
        <ErrorBoundary onError={triggerSentryError} fallback={<LoadServiceError t={t} />}>
          <SuspenseLoader>
            <PaymentsOperationsService
              modules={{ LimitInfo }}
              events={{
                triggerKYCModal,
                triggerPaymentMethodChanged,
                resetOperationsStore,
                triggerLegalOrderDetailsChanged: legalOrderDetailsChanged,
                toggleChat,
              }}
              stores={{
                $translate,
                $activeAccount,
                $walletsList,
                $selectedPaymentMethod,
                $fiatList,
                $cryptoList,
                $KYCLevel,
                $accountsLocations,
                $roundToByCurrency,
                $currencyList,
                $verificationType,
                $rate,
                $techSupportLink,
              }}
              fxs={{
                notify: notifyFx,
                toConfirmWithdrawFx,
                getLegalOrderDetailsFx,
              }}
              reqData={{
                userEndpoints: {
                  PASS_TG_CODE: prop('PASS_TG_CODE', userEndpoints),
                },
              }}
              createFxFabric={createApiRetryEffect}
            />
          </SuspenseLoader>
        </ErrorBoundary>
      }
      right={
        <ErrorBoundary onError={triggerSentryError} fallback={<LoadServiceError t={t} />}>
          <SuspenseLoader>
            <HistoryService
              createFxFabric={createApiRetryEffect}
              stores={{
                $roundToByCurrency,
                $currentHistoryId: $selectedPaymentsHistory,
              }}
              fxs={{
                notify: notifyFx,
              }}
              subHash='payments'
              fns={{
                isNotDemo,
                handleOpenTransactionModal,
                triggerResetStores: triggerResetHistory,
                historyChangedByWS: triggerUpdateAssetsHistory,
              }}
              reqData={{
                historyEndpoint: constructEndpoint('/cb-transfer/order/fetch'),
              }}
            />
          </SuspenseLoader>
        </ErrorBoundary>
      }
    />
  )
}
