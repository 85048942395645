import { createEvent } from 'effector'
import { CaptchaSignature, FailedOn401Signature } from './types'

export const triggerLogout = createEvent()

export const triggerCaptchaChanged = createEvent<CaptchaSignature>()

export const triggerKYCModal = createEvent<{ isError: boolean }>()

export const triggerSentryError = createEvent<string | unknown>()

export const openRefreshOverlay = createEvent()
export const hideRefreshOverlay = createEvent()

export const addToFailed401ReqChanged = createEvent<FailedOn401Signature>()
export const removeFromFailed401ReqChanged = createEvent<string[]>()
