import { Flex, MediaQuery, Paper } from '@mantine/core'
import {
  LoadServiceError,
  ProcessingResponse,
  SuspenseLoader,
  getAdditionSizes,
} from 'ap-core'
import { $email, $navigate, $translate, notifyFx, withErrorNotification } from 'entities'
import { LoginFormWithLogo } from 'entities/ui/auth-banner'
import { importRemote } from 'module-federation-import-remote'
import { lazy } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import {
  StyledScrollArea,
  getGlobalArg,
  getLayoutHeight,
  processingResponseKy,
  request,
  triggerSentryError,
  useGetLayoutSpacings,
} from 'shared'

const AuthBanner = lazy(() => import('entities/ui/auth-banner'))

const RemoteLogin = lazy(() =>
  importRemote({
    url: getGlobalArg('LOGIN_REMOTE_LINK'),
    scope: 'remoteLogin',
    module: 'Login',
  })
)
const Banner = (
  <SuspenseLoader>
    <AuthBanner />
  </SuspenseLoader>
)

export const LoginPage = () => {
  const spacing = useGetLayoutSpacings()

  const { t } = useTranslation()
  return (
    <StyledScrollArea
      h={{
        base: `calc(100dvh - ${getLayoutHeight(spacing)})`,
      }}
    >
      <Flex h='100%' justify='center' gap='0.5rem'>
        <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
          <Paper w={{ base: '100%', md: '50%' }} shadow='md' withBorder radius='lg'>
            <Flex
              mah={500}
              maw={500}
              justify='center'
              align='center'
              m='auto'
              style={{
                position: 'relative',
                top: 'calc(50% - 250px)',
                left: 0,
                right: 0,
              }}
              pos='relative'
            >
              {Banner}
            </Flex>
          </Paper>
        </MediaQuery>
        <Paper
          py={getAdditionSizes('s32')}
          w={{ base: '100%', md: '50%' }}
          shadow='md'
          withBorder
          radius='lg'
        >
          <Flex
            pos='relative'
            w='100%'
            h='100%'
            justify='center'
            align='center'
            direction='column'
          >
            <LoginFormWithLogo banner={Banner}>
              <ErrorBoundary
                onError={triggerSentryError}
                fallback={<LoadServiceError t={t} />}
              >
                <SuspenseLoader>
                  <RemoteLogin
                    stores={{ $email, $translate, $navigate: $navigate }}
                    fxs={{
                      notify: notifyFx,
                    }}
                    reqData={{
                      kyInstance: request,
                      processingResponse: processingResponseKy as ProcessingResponse,
                    }}
                    createFxFabric={withErrorNotification}
                  />
                </SuspenseLoader>
              </ErrorBoundary>
            </LoginFormWithLogo>
          </Flex>
        </Paper>
      </Flex>
    </StyledScrollArea>
  )
}
